import React from 'react';
import {
  Added,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
  Removed,
} from 'docComponents';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="June 2019" subnav="release-notes">
      <div id="June2019" className={styles.release}>
        <Paragraph className="uni-margin--one--vert">
          This month's list may be short, but the work was plenty. We've
          officially moved to Figma from Sketch! Our library was completely
          rebuilt from the ground up, and we think it might be even better than
          the last. We also added new components for React Native, read below to
          find out which.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>All you need to know about a minimal notice.</Added>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.31.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.31.0"
            />

            <div className={styles.changesList}>
              <Added>
                That{' '}
                <Link href="/components/notifications/notice/design">
                  minimal
                </Link>{' '}
                style variant of the notice.
              </Added>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="7.1.1"
              versionUrl="https://github.com/hudl/rn-uniform-ui/releases/tag/v7.1.1"
            />
            <div className={styles.changesList}>
              <Added>
                Our newest component, the{' '}
                <Link href="/components/card/design">card</Link>.
              </Added>
              <Added>
                The fancy{' '}
                <Link href="/components/select-mark/design">select mark</Link>{' '}
                as well.
              </Added>
              <Improved>
                Our <Link href="/components/type/design">type</Link> components
                by including an <code>alignment</code> prop.
              </Improved>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />

            <div className={styles.changesList}>
              <Added>We built the whole shebang. It's finally here!</Added>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Sketch Library" />

            <div className={styles.changesList}>
              <Removed>
                As of this release, we're deprecating the Sketch library.
              </Removed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
